import { AxiosResponse } from 'axios';
import { IMeta, IParamsObject, IServerResponse } from '@/api/types';
import { axiosInstance } from '@/api/index';

import { API_REVENUE_URL, API_REVENUE_URL_V2 } from '@/configs/global';

import {
    IAdvertiser,
    IAdvertiserOffer,
    IAffiliate,
    IContractor,
    IContractorAddress,
    IConversionChanges,
    IConversionChangesList,
    IDebtors,
    IDebtorsTotal,
    IFileReportSummary,
    IFinmediaData,
    IInvoice,
    IInvoices,
    ILegalEntityOrg,
    ILegalEntitySettingsOrg,
    INettingItem,
    IOfferService,
    IOtherService,
    IOtherServiceCreate,
    IPartnerAccount,
    IReportAdjustOrClone,
    IReportApproved,
    IReportLeadDuplicate,
    IReportSummary,
    IStatTask,
    IStatTasks,
    ITag,
} from '@/api/types/revenue';

/**
 *  Получение списка прочих услуг
 */
export async function getInvoiceOtherServices(
    params?: IParamsObject,
): Promise<IServerResponse<IOtherService[] | IFinmediaData>> {
    return axiosInstance.request<IServerResponse<IOtherService[] | IFinmediaData>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/other-services`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IOtherService[] | IFinmediaData>>) => response.data);
}

/**
 *  Создать прочих услуг
 */
export async function postInvoiceOtherServices(
    data: IOtherServiceCreate,
): Promise<IServerResponse<IOtherService[] | IFinmediaData>> {
    return axiosInstance.request<IServerResponse<IOtherService[] | IFinmediaData>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/invoices/other-services`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOtherService[] | IFinmediaData>>) => response.data);
}

/**
 *  Редактирование прочих услуг
 */
export async function patchInvoiceOtherServices(
    id: number,
    data: {is_active: boolean},
): Promise<IServerResponse<IOtherService[] | IFinmediaData>> {
    return axiosInstance.request<IServerResponse<IOtherService[] | IFinmediaData>>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/invoices/other-services/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOtherService[] | IFinmediaData>>) => response.data);
}

/**
 *  Создание/ редактирование счёта
 *  Редактирование в случае, если указан id.
 */
export async function saveInvoice(
    bodyParams: IInvoice,
    id?: number,
): Promise<IInvoice> {
    return axiosInstance.request<IServerResponse<IInvoice>>({
        method: id ? 'PATCH' : 'POST',
        url: id ? `${API_REVENUE_URL}/invoices/${id}` : `${API_REVENUE_URL}/invoices`,
        data: {
            ...bodyParams,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IInvoice>>) => response.data.data);
}

/**
 *  Получение списка рекламодателей
 */
export async function getAllAdvertisers(
    params?: IParamsObject,
): Promise<IServerResponse<IAdvertiser[]>> {
    return axiosInstance.request<IServerResponse<IAdvertiser[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IAdvertiser[]>>) => response.data);
}

/**
 * Добавление доп менеджера рекламодателю
 */
export async function addAdvertiserManager(
    advertiserId: number,
    data: {
        manager_id: string,
    },
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/managers`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/**
 * Удалить доп менеджера у рекла
 */
export async function deleteAdvertiserManager(
    advertiserId: number,
    id: number,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/managers/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 *  Получение списка должников
 */
export async function getDebtors(
    params: {},
): Promise<{meta: IMeta, data: IDebtors[]}> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/debtors`,
        params,
        headers: {
            'Accept': 'application/json',
        },
    })
        .then((response) => response.data);
}


/*
 *  Получение `Итого` в списке должников
 */
export async function getDebtorsTotal(
    params: {},
): Promise<IDebtorsTotal[]> {
    return axiosInstance.request({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/debtor-total`,
        params,
    })
        .then((response) => response.data);
}

/*
 *  Экспорт должников в xlsx
 */
export async function getDebtorsExcel(
    params: {},
): Promise<object> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/debtors`,
        responseType: 'blob',
        params,
        headers: {
            'Accept': 'application/vnd.ms-excel',
        },
    })
        .then((response) => response.data);
}


/**
 *  Получение списка счетов
 */
export async function getAllInvoices(
    params?: IParamsObject,
): Promise<IServerResponse<IInvoices[]>> {
    return axiosInstance.request<IServerResponse<IInvoices[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL_V2}/invoices`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IInvoices[]>>) => response.data);
}

/**
 *  Получение одного счета
 */
export async function getOneInvoice(
    id: number,
    params?: IParamsObject,
): Promise<IInvoice> {
    return axiosInstance.request<IServerResponse<IInvoice>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/${id}`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IInvoice>>) => response.data.data);
}

/**
 *  Получение тегов рекламодателей
 */
export async function getAdvertiserTags(): Promise<IServerResponse<ITag[]>> {
    return axiosInstance.request<any>({
        method: 'GET',
        url: `${API_REVENUE_URL}/tags`,
    })
        .then((response: AxiosResponse<IServerResponse<ITag[]>>) => response.data);
}

/**
 *  Получение списка юр.лиц
 */
export async function getLegalEntities(
    params?: IParamsObject,
): Promise<IServerResponse<ILegalEntityOrg[]>> {
    return axiosInstance.request<IServerResponse<ILegalEntityOrg[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/legal-entities`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<ILegalEntityOrg[]>>) => response.data);
}

/**
 *  Получение одного юр.лца
 */
export async function getOneLegalEntity(
    id: number,
): Promise<ILegalEntityOrg> {
    return axiosInstance.request<IServerResponse<ILegalEntityOrg>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/legal-entities/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ILegalEntityOrg>>) => response.data.data);
}

/**
 *  Редактирование юр.лица
 */
export async function patchLegalEntity(
    id: number,
    data: ILegalEntitySettingsOrg,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/invoices/legal-entities/${id}`,
        data,
    })
        .then((response: object) => response);
}

/**
 *  Получение одного рекламодателя по id
 */
export async function getOneAdvertiser(
    id: number,
): Promise<IAdvertiser> {
    return axiosInstance.request<IServerResponse<IAdvertiser>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IAdvertiser>>) => response.data.data);
}

/**
 *  Получение списка оферов по id рекламодателя
 */
export async function getOneAdvertiserOffers(
    id: number,
    params: {
        limit: number;
        offset: number;
    },
): Promise<IServerResponse<IAdvertiserOffer[]>> {
    return axiosInstance.request<IServerResponse<IAdvertiserOffer[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers/${id}/offers`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAdvertiserOffer[]>>) => response.data);
}

/**
 *  Получение списка партнеров по id рекламодателя
 */
export async function getOneAdvertiserAffiliates(
    id: number,
    params: { limit: number } = { limit: 100 },
): Promise<IAffiliate[]> {
    return axiosInstance.request<IServerResponse<IAffiliate[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers/${id}/affiliates`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliate[]>>) => response.data.data);
}

/**
 *  Получение рекламодателя включающим в себя списке его оферов и партнеров
 */
export async function getOneAdvertiserCompiled(
    id: number,
): Promise<IAdvertiser> {
    return getOneAdvertiser(id).then(async (advertiser: IAdvertiser) => {
        const getAffiliates = getOneAdvertiserAffiliates(id);
        try {
            advertiser.affiliates = await getAffiliates;
        } catch {
            advertiser.affiliates = [];
        }
        return advertiser;
    });
}

/*
 * Добавление адресов рекламодателя
 */
export async function addAdvertiserAddress(
    advertiserId: number,
    contractorId: number,
    address: IContractorAddress,
): Promise<{ id: number }> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractor/${contractorId}/addresses`,
        data: {
            ...address,
        },
    })
        .then((response) => response.data.data);
}

/*
 * Редактирование адреса рекламодателя
 */
export async function editAdvertiserAddress(
    advertiserId: number,
    contractorId: number,
    address: IContractorAddress,
): Promise<any> {
    return axiosInstance({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractor/${contractorId}/addresses/${address.id}`,
        data: {
            name: address.name,
            address: address.address,
        },
    });
}

/*
 * Удаление адресов рекла
 */
export async function removeAdvertiserAddress(
    advertiserId: number,
    contractorId: number,
    addressId: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractor/${contractorId}/addresses/${addressId}`,
    });
}

/**
 *  Отправка массива дат автоматического создание счетов для одного рекламодателя
 */
export async function postInvoicingDates(
    id: number,
    data: {
        days: number[],
    },
): Promise<object> {
    return axiosInstance.request<IServerResponse<{ days: number[] }>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/advertisers/${id}/invoicing-dates`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{ days: number[] }>>) => response.data);
}

/**
 *  Добавление партнерских аккаунтов
 */
export async function postPartnerAccounts(
    id: number,
    data: IPartnerAccount,
): Promise<object> {
    return axiosInstance.request<IServerResponse<IPartnerAccount>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/advertisers/${id}/affiliates`,
        data: {
            affiliate_id: data.affiliate_id,
            is_need_to_overwrite: data.is_need_to_overwrite,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IPartnerAccount>>) => response.data.data);
}

/**
 *  Удаление партнерских аккаунтов
 */
export async function deletePartnerAccounts(
    id: number,
    data: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/advertisers/${id}/affiliates`,
        data: {
            affiliate_id: data,
        },
    });
}

/**
 *  Редактирование тега
 */
export async function patchAdvertisersTags(
    id: number,
    data: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${id}/tags`,
        data: {
            tag_id: data,
        },
    })
        .then((response: object) => response);
}

/**
 *  Редактирование чеккера
 */
export async function patchAdvertisersChecker(
    id: number,
    data: boolean,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${id}/switch-checker`,
        data: {
            is_checker_used: data,
        },
    })
        .then((response: object) => response);
}

/**
 *  Изменить статус оффера
 */
export async function patchOfferStatus(
    statTaskId: number,
    offerServiceId: number,
    data?: object,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/stat-tasks/${statTaskId}/offer-service/${offerServiceId}/status`,
        data,
    })
        .then((response: object) => response);
}

/**
 *  Поиск рекламодателя по `id` или `name`
 */
export async function getAdvertisersSearch(
    params?: IParamsObject,
): Promise<IServerResponse<IAdvertiser[]>> {
    return axiosInstance.request<IServerResponse<IAdvertiser[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers/search`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IAdvertiser[]>>) => response.data);
}

/**
 *  Поиск юр.лиц по `id` или `name`
 */
export async function getLegalEntitySearch(
    params?: IParamsObject,
): Promise<object> {
    return axiosInstance.request<IServerResponse<ILegalEntityOrg[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/legal-entities/search`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<ILegalEntityOrg[]>>) => response.data);
}

/**
 *  Получение списка неоплаченных счетов вебмастеров
 */
export async function getUnpaidInvoices(
    id: number,
    params?: IParamsObject,
): Promise<IServerResponse<INettingItem[]>> {
    return axiosInstance.request<IServerResponse<INettingItem[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL_V2}/advertisers/${id}/unpaid-invoices`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<INettingItem[]>>) => response.data);
}

/**
 *  Получение списка загрузок по id рекламодателя
 */
export async function getAllStatTasks(
    params?: IParamsObject,
): Promise<IServerResponse<IStatTasks[]>> {
    return axiosInstance.request<IServerResponse<IStatTasks[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/stat-tasks`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IStatTasks[]>>) => response.data);
}

/**
 *  Получение списка загрузок по id рекламодателя
 */
export async function getOneStatTask(
    id: number,
): Promise<IStatTasks> {
    return axiosInstance.request<IServerResponse<IStatTasks>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/stat-tasks/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IStatTasks>>) => response.data.data);
}

/**
 *  Создание задачи на загрузку по id рекламодателя
 */
export async function postStatTask(
    data: IStatTask,
): Promise<IStatTask> {
    return axiosInstance.request<IServerResponse<IStatTask>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/stat-tasks`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IStatTask>>) => response.data.data);
}

/**
 * Редактирование задачи на загрузку по id рекламодателя
 */
export async function patchStatTask(
    id: number,
    data: IStatTask,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/stat-tasks/${id}`,
        data,
    })
        .then((response: object) => response);
}

/**
 * Получение списка офферов одного рекламодателя
 */
export async function getOfferServices(
    advertiserId: number,
    params?: IParamsObject,
): Promise<IOfferService[]> {
    return axiosInstance.request<IServerResponse<IOfferService[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/invoices/offer-services?advertiser_id=${advertiserId}`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IOfferService[]>>) => response.data.data);
}

/**
 *  Добавление комментария к файлу
 */
export async function postStatTaskComment(
    statTaskId: number,
    fileId: number,
    data: object,
): Promise<{id: number}> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/stat-tasks/${statTaskId}/${fileId}/comment`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data.data);
}

/**
 *  Получение файла договора рекламодателя для скачивания
 */
export async function downloadAdvertiserContract(
    id: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/advertisers/file/${id}`,
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Сделать контрагента рекламодателя дефолтным
 */
export async function setDefaultContractor(
    advertiserId: number,
    contractorId: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractor/${contractorId}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response);
}

/*
 * Получение списка всех контрагентов
 */
export async function getAllContractors(
    params: {
        limit?: number,
        query: string,
    },
): Promise<IServerResponse<IContractor[]>> {
    return axiosInstance.request<IServerResponse<IContractor[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/contractors`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IContractor[]>>) => response.data);
}

/*
 * Получение списка всех контрагентов по guid
 */
export async function getAllContractorsGuids(
    guids: [],
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/contractors`,
        params: {
            guids,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/*
 * Создание связи рекламодателя и контрагента
 */
export async function addContractor(
    advertiserId: number,
    contractorGuid: string,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractors`,
        params: {
            contractor_guid: contractorGuid,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/*
 * Удаление связи рекламодателя и контрагента
 */
export async function deleteContractor(
    advertiserId: number,
    contractorGuid: string,
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/advertisers/${advertiserId}/contractors`,
        params: {
            contractor_guid: contractorGuid,
        },
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/**
 *  Запрос на изменение проекта
 */
export async function projectsChangeRequest(
    id: number,
    data: string[],
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${id}/projects`,
        data: {
            projects: data,
        },
    })
        .then((response: object) => response);
}

/**
 *  Запрос на изменение валюты
 */
export async function currenciesChangeRequest(
    id: number,
    data: string[],
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/advertisers/${id}/currencies`,
        data: {
            currencies: data,
        },
    })
        .then((response: object) => response);
}

/**
 *  Скачивание файла
 */
export async function downloadFiles(
    id: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/files/${id}`,
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/**
 *  Изменить статус загрузки
 */
export async function patchStatTaskStatus(
    id: number,
    data: object,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/stat-tasks/${id}/status`,
        data,
    })
        .then((response: object) => response);
}

/**
 *  Удаление задачи на загрузку статистики из списка
 */
export async function deleteStatTask(
    id: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/stat-tasks/${id}`,
    });
}

/**
 *  Удаление счета из списка
 */
export async function deleteInvoice(
    id: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/invoices/${id}`,
    });
}

/**
 *  Редактирование своего комментария в загрузке
 */
export async function patchStatTaskComment(
    statTaskId: number,
    fileDataId: number,
    id: number,
    data: string,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'PATCH',
        url: `${API_REVENUE_URL}/stat-tasks/${statTaskId}/${fileDataId}/comment/${id}`,
        data: {
            text: data,
        },
    })
        .then((response: object) => response);
}

/**
 *  Удаление своего комментария в загрузке
 */
export async function deleteStatTaskComment(
    statTaskId: number,
    fileDataId: number,
    id: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/stat-tasks/${statTaskId}/${fileDataId}/comment/${id}`,
    });
}

/*
 * Получение списка счетов финмедиа
 */
export async function getFinmediaInvoices(
    params?: {
        ids?: [];
        projects?: [];
        account_manager_id?: [];
        financial_status?: string;
        period_from?: string;
        period_to?: string;
    },
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'GET',
        url: `${API_REVENUE_URL_V2}/invoices`,
        params: { ...params, is_finmedia: 1 },
    })
        .then((response) => response.data);
}

/*
 * Добавить отчет
 */
export async function postReport(
    data: {
        stat_task_id: string | number;
        offer_service_id: string | number;
        report: any,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/reports`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Получение отчета по файлу
 */
export async function getFileReport(
    id: number,
): Promise<IServerResponse<IFileReportSummary>> {
    return axiosInstance.request<IServerResponse<IFileReportSummary>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/summary/file_data/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IFileReportSummary>>) => response.data);
}

/*
 * Проверяем запускался ли ответ раньше
 * если tasks в ответе пустое, значит не запускался
 */
export async function getConversionsReport(
    reportId: number,
): Promise<IServerResponse<IConversionChanges>> {
    return axiosInstance.request<IServerResponse<IConversionChanges>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${reportId}/conversion-changes`,
    })
        .then((response: AxiosResponse<IServerResponse<IConversionChanges>>) => response.data);
}

/*
 * Обновление отчета по изменению цен и статусов
 */
export async function postConversionsReport(
    reportId: number,
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/reports/${reportId}/conversion-changes`,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Скачать отчет по изменению цен и статусов
 */
export async function exportConversionsReport(
    reportId: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${reportId}/conversion-changes/list/excel`,
        responseType: 'blob',
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}

/*
 * Получение детальной информации по отчету по иземениню цен и статусов
 */
export async function getConversionsReportList(
    reportId: number,
    params: {
        change_type?: string;
        offer_id?: string;
        transaction_id?: string;
        sub_id?: string;
        lead_id?: string;
        affiliate_id?: string;
        lead_date?: string;
        change_date?: string;
        oldStatus?: string;
        newStatus?: string;
    },
): Promise<IServerResponse<IConversionChangesList[]>> {
    return axiosInstance.request<IServerResponse<IConversionChangesList[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${reportId}/conversion-changes/list`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IConversionChangesList[]>>) => response.data);
}

/*
 * Получение общей (агрегированной информации об отчёте)
 */
export async function getSummaryReport(
    id: number,
): Promise<IServerResponse<IReportSummary>> {
    return axiosInstance.request<IServerResponse<IReportSummary>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IReportSummary>>) => response.data);
}

/*
 * Получение аджастов
 */
export async function getAdjustsReport(
    id: number,
): Promise<IServerResponse<IReportAdjustOrClone[]>> {
    return axiosInstance.request<IServerResponse<IReportAdjustOrClone[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/adjusts`,
    })
        .then((response: AxiosResponse<IServerResponse<IReportAdjustOrClone[]>>) => response.data);
}

/*
 * Добавить аджасты
 */
export async function addAdjust(
    id: number,
    data: { url: string },
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/reports/${id}/adjust`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Получение клонов
 */
export async function getClonesReport(
    id: number,
): Promise<IServerResponse<IReportAdjustOrClone[]>> {
    return axiosInstance.request<IServerResponse<IReportAdjustOrClone[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/clones`,
    })
        .then((response: AxiosResponse<IServerResponse<IReportAdjustOrClone[]>>) => response.data);
}

/*
 *  Добавить клонов в отчет
 */
export async function addClone(
    id: number,
    data: { url: string },
): Promise<IServerResponse<[]>> {
    return axiosInstance.request<IServerResponse<[]>>({
        method: 'POST',
        url: `${API_REVENUE_URL}/reports/${id}/clones`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<[]>>) => response.data);
}

/*
 * Потери (отчет)
 */
export async function getLoss(
    id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/loss`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Лишние (отчет)
 */
export async function getExtra(
    id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/extra`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Дубли у реклов (отчет)
 */
export async function getAdvertiserDuplicates(
    id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/dublicates_adv`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/*
 * Дубли лидгид (отчет)
 */
export async function getLeadDuplicates(id: number): Promise<IServerResponse<IReportLeadDuplicate[]>> {
    return axiosInstance.request<IServerResponse<IReportLeadDuplicate[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/dublicates_ldg`,
    })
        .then((response: AxiosResponse<IServerResponse<IReportLeadDuplicate[]>>) => response.data);
}

/*
 * Принято (новые или старые) (отчет)
 */
export async function getReportApproved(
    id: number,
    type: string,
): Promise<IServerResponse<IReportApproved[]>> {
    return axiosInstance.request<IServerResponse<IReportApproved[]>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/${id}/approved_${type}`,
    })
        .then((response: AxiosResponse<IServerResponse<IReportApproved[]>>) => response.data);
}

/**
 *  Удаление( soft delete) отчета
 */
export async function deleteReport(
    id: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_REVENUE_URL}/reports/${id}`,
    });
}
export async function getGeneralReport(id: number): Promise<IServerResponse<IFileReportSummary>> {
    return axiosInstance.request<IServerResponse<IFileReportSummary>>({
        method: 'GET',
        url: `${API_REVENUE_URL}/reports/summary/stat_task/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IFileReportSummary>>) => response.data);
}

/*
 * Обновление статуса заказа покупателя
 */
export async function updateCustomerOrderStatus(
    data: [{
        id: number;
        version: string;
        state: string,
    }],
): Promise<object> {
    return axiosInstance.request<object[]>({
        method: 'POST',
        url: `${API_REVENUE_URL}/invoices/customer_orders`,
        data,
    })
        .then((response: AxiosResponse<object>) => response.data);
}
